<template>
<div></div>
</template>
<script>
import { useAuthStore } from '../stores';
import { ref } from "vue";
import config from "../config";

export default {
  name: "LoginRedirect",
  props: {
    language: { type: String, default: "en" },
  },
  setup(props) {
    const login_url = ref('')
    const authStore = useAuthStore()
    login_url.value = authStore.keycloak.createLoginUrl({redirectUri: config.FRONTEND_URL,locale:props.language})
    window.location.href = login_url.value
    return {}
  }
}
</script>
