<template>
  <div
    class="components-form-input"
    :class="{
      'components-form-input-right': labelPosition === 'right'
    }"
  >
    <label
      :for="name"
      :class="{
        'hide': !label,
        'error': fieldError,
      }"
    >
      {{ label ? ($t(label, labelOption) + (required ? "" : $t('general.optional'))) : "" }}
    </label>
    <slot />
    <small
      v-if="help"
      :id="name+'-help'"
    >
      {{ $t(help) }}
    </small>
    <small
      v-if="fieldError"
      :id="name+'field-error'"
      class="error"
    >
      {{ $t((fieldErrorMsg ? fieldErrorMsg : 'general.form.field_errors.'+type), {name: label ? $t(label, labelOption) : name}) }}
    </small>
  </div>
</template>

<script>

export default {
  name: "InputWrapper",
  props: {
    type: {type: String, default: "text"},
    name: {type: String, default: ""},
    help: {type: String, default: ""},
    label: {type: String, default: ""},
    labelOption: {type: Object, default: () => {}},
    labelPosition: {type: String, default: 'top'},
    required: {type: Boolean, default: false},
    fieldError: {type: Boolean, default: false},
    fieldErrorMsg: {type: String, default: ''},
  },
  // setup() {
  //
  //   const field_error = ref(false);
  //   // TODO: manage field error: required, rules
  //   // const email_rule = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/
  //   //
  //   // const containsUppercaseRule = /[A-Z]/
  //   // const containsLowercaseRule = /[a-z]/
  //   // const containsNumberRule = /[0-9]/
  //   // const containsSpecialRule = /[`!@#$%^&*()_\-+=[\]{};':"\\|,.<>/?~ ]/
  //   // const configuredRules = {
  //   //   'email': (val) => {
  //   //     return !email_rule.test(String(val).toLowerCase())
  //   //   },
  //   //   'password': (val) => {
  //   //     return !(
  //   //       containsUppercaseRule.test(val)
  //   //       && containsLowercaseRule.test(val)
  //   //       && containsNumberRule.test(val)
  //   //       && containsSpecialRule.test(val)
  //   //       && val .length > 7
  //   //     )
  //   //   }
  //   // }
  //
  //   return {
  //     field_error,
  //   };
  // },
};
</script>

<style scoped>
.components-form-input {
  /*margin: 0.75rem 0;*/
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.components-form-input-right {
    width: fit-content;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
}
.components-form-input-right > .input-wrapper {
  width: fit-content !important;
}
.components-form-input-right > label {
  /*color: var(--Grays-Gray-800, #1E293B);*/
  /*font-feature-settings: 'calt' off;*/
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5rem !important;
}
.input-wrapper > div,
.input-wrapper > div > input,
.input-wrapper > div > textarea,
.input-wrapper > div > span,
.input-wrapper > div > div > input {
  width: 100%;
}
.components-form-input > label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
  color: var(--Text-default, #121520);
  /* text-sm/bold */
  font-family: var(--font-text);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem; /* 133.333% */
}
.components-form-input > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.21875rem;
  width: 100%;
  /*width: fit-content;*/
}
.hide {
  display: unset;
}
.input-wrapper {
  display: grid;
  /* width: calc(100% - 2rem); */
  /*background: var(--color-gray-lighten);*/
  border-radius: 4px;
  grid-template-columns: auto;
}

.input-wrapper_select {
  /*background: none;*/
  max-height: 3rem;
/*  margin-bottom: 2.5rem;*/
}

.input-wrapper_select button {
  background: var(--color-gray-lighten);
}

.input-wrapper_select.input-wrapper.input-wrapper__icon span {
  align-self: start;
  background: var(--color-gray-lighten);
}

.input-wrapper_select > .fas-icon {
  padding: 0.75rem 0;
}

.z-10 {
  z-index: 10;
}

.input-wrapper.input-wrapper__icon {
  grid-template-columns: 2.5rem auto;
}

.input-wrapper.input-wrapper__password {
  grid-template-columns: auto 2.5rem;
}

.input-wrapper.input-wrapper__icon.input-wrapper__password {
  grid-template-columns: 2.5rem auto 2.5rem;
}

input {
  display: block;
}

span {
  display: inline-block;
  color: var(--color-gray-darken);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-22);
  align-self: center;
  justify-self: center;
}

.input-select {
  width: 100%;
}

.select-dropdown {
  position: inherit;
  width: inherit;
}

.input-wrapper__disabled input,
.input-wrapper__disabled span {
  color: var(--color-gray);
}
</style>
