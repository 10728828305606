import TrackedModel from "@/resources/TrackedModel";
import Subscription from "@/resources/Subscription";
import {useUserRoles} from "@/composables/userData";

export default class User  extends TrackedModel {

  constructor(data) {
    super(data);
    const { userId, userIsAnyAdmin, userIsManager } = useUserRoles();
    this.first_name = data.first_name || "";
    this.last_name = data.last_name || "";
    this.full_name = this.first_name + ' ' + this.last_name;
    this.username = data.username || "";
    this.is_superuser = data.is_superuser || false;
    // this.password = data.password || "";
    this.email = data.email || "";
    this.is_active = data.id === 'NO_ID' ? true : data.is_active;
    this.email_notification = data.email_notification || false;
    this.privacy_policy = data.profiled_info ? data.profiled_info.privacy_policy : data.privacy_policy;
    this.profiled_info = data.profiled_info;
    this.language = data.language || "";
    this.lang = data.lang || 'en';
    this.occupation_1 = data.occupation_1 || "";
    this.occupation_2 = data.occupation_2 || "";
    this.date_onboarding = data.date_onboarding || null;
    this.skill_names = data.skill_names || [];
    this.occupation_1_name = data.occupation_1_name === null ? "" : data.occupation_1_name;
    this.occupation_2_name = data.occupation_2_name === null ? "" : data.occupation_2_name;
    this.occupation_names = this.occupation_1_name ? this.occupation_1_name.trim() : '' + ( this.occupation_2_name ? ', ' + this.occupation_2_name.trim() : '');
    this.role = data.role || "";
    this.allowed_content_providers = data.allowed_content_providers || "";
    this.skills = data.skills || "";
    this.has_tech_knowledge = data.has_tech_knowledge || 0;
    this.organization = data.organization || "";
    this.organization_id = data.organization_id || "";
    this.organization_info = data.organization_info || {};
    this.email_status = data.email_status || "";
    this.signup_status = data.signup_status || "";
    this.report = data.report || "";
    this.profile = data.profile || "";
    this.itw_status = data.itw_status || "";
    this.profile_display = data.profile_display || "";
    this.group_counter = data.group_counter || 0;
    this.groups_assigned_counter = data.groups_assigned_counter || 0;
    this.group_names = data.group_names || "";
    this.is_referent = data.is_referent || "";
    this.points = data.points || 0;
    this.current_subscription = data.current_subscription ? new Subscription(data.current_subscription) : null;
    this.current_subscription_str = data.current_subscription ? this.current_subscription.getDetails() : '';
    this.plan = data.plan || this.current_subscription?.plan ||  0;

    this.owner_of = data.owner_of || [];
    this.addons_info = data.addons_info || [];
    this.addon_catalogs = data.addon_catalogs || [];

    this.disabled = !this.is_active || !this.current_subscription;
    // this.permissions.delete = !this.itw_status && (userIsAdmin() || userIsAnyAdmin()) && userId() !== this.id;
    this.permissions.delete  = false; // userIsAdmin() || userIsSales();
    // You cannot switch plan for a users not already active.
    this.permissions.manage = this.is_active && userIsAnyAdmin() && data.current_subscription && TrackedModel.asDate(this.current_subscription.start_date)< new Date();

    this.permissions.add_user = userIsAnyAdmin();
    this.permissions.deactivate = userIsAnyAdmin() && this.is_active && userId() !== this.id;
    this.permissions.activate = userIsAnyAdmin() && !this.is_active;
    this.permissions.add_plan = userIsAnyAdmin();
    this.permissions.add_team = userIsAnyAdmin() || userIsManager();
    return this;
  }

  static fromApiResult(userData) {
    const userObj = new User(userData);
    return userObj;
  }

  convertForListBoxOption() {
    return {
      key: this.id,
      label: this.full_name,
      email: this.email
    };
  }
  getCurrentPlanTrialEndDate() {
    if (this.current_subscription && this.current_subscription.plan_status
      && this.current_subscription.plan_status !== 'expired') {
      const today = new Date();
      const endDate = TrackedModel.asDate(TrackedModel.asInputDate(this.current_subscription.plan_trial_end_date));
      return {
        'expireDate': this.current_subscription.plan_expiration_date ? TrackedModel.asOutputDate(this.current_subscription.plan_expiration_date, 'it') : '',
        'renewalDateStr': this.current_subscription.plan_renewal_date_str,
        'isInTrial': this.isInTrial,
        'frequency': this.current_subscription.plan_frequency,
        'trialWarning': this.current_subscription.trialWarning,
        'totalDays': parseInt((endDate - today) / (1000 * 60 * 60 * 24))
      };
    }
    return {};
  }
  static getValidListOptions(records, fieldName, defaultEmptyLabel, addEmptyItem= true){
    // let plansForListBoxOption = plans.filter(plan => !plan.is_expired && plan.total_license > plan.used_license).map(record => {
    let forListBoxOption = [];
    if (addEmptyItem) {
      forListBoxOption.push({key: '', label: defaultEmptyLabel, email: ''});
    }
    if (records) {
      forListBoxOption = forListBoxOption.concat(records.map(record => {
        return record.convertForListBoxOption();
      }));
    }
    return forListBoxOption;
  }
}
