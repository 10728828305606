<template>
  <div class="organization-form">
    <form
      data-test="create-organization"
      @submit.prevent="submitToApi"
    >
      <Alert
        v-if="errorMsg"
        :title="$t('organizations.error_saving_user')"
        type="error"
      >
        <template #content>
          {{ errorMsg }}
        </template>
      </Alert>
      <div class="flex gap-6 flex-col">
        <InputWrapper
          name="small_name"
          type="text"
          label="organizations.small_name"
          :required="true"
          :field-error="!refRecord.name && showError"
        >
          <InputText
            v-model.trim="refRecord.name"
            name="name"
            :invalid="!refRecord.name && showError"
            min-value="6"
            :disabled="loading"
          />
        </InputWrapper>
        <div class="flex flex-col-reverse gap-4 justify-end md:flex-row md:gap-6">
          <Button
            :label="$t('general.cancel')"
            severity="tertiary"
            @click.prevent="cancel"
          />
          <Button
            data-test="submit"
            :label="$t('general.save')"
            :loading="loading"
            @click="submitToApi"
          />
        </div>
        <!-- :disabled="!canSubmit" -->
      </div>
    </form>
  </div>
</template>
<script setup>
import {ref, defineEmits, defineProps} from 'vue'
import { useOrganizations } from '@/composables/useOrganizations'
import { useToasts } from "@/composables/useToasts";

import Alert from "@/components/Alert.vue";
import InputWrapper from "@/components/common/InputWrapper.vue";
import Button from 'primevue/button';
import InputText from "primevue/inputtext";

// name: 'OrganizationSmallForm',
const props = defineProps({
    id: { type: String, required: false, default: null },
    record: { type: Object, default: () => {},},
  })
const emit = defineEmits([
  'refreshName',
  'refresh',
  'closeModal',
])

const loading = ref(false)

const canSubmit = ref(true) // disabled in template

const refRecord = ref(props.record)
const invariantName = ref('')
const errorMsg = ref('')
const { updateOrganization } = useOrganizations()
const { toastSuccess, toastError } = useToasts();

invariantName.value = props.record.name

const afterSave = (result) => {
  loading.value = false;
  if (result.error) {
    errorMsg.value = result.error.toString()
    toastError('Error', errorMsg.value.toString())
  } else {
    refRecord.value = result;
    toastSuccess('', 'organizations.updated', {
      translation:{
        organization: refRecord.value.name
      }
    })

    emit('refreshName', refRecord.value.name)
    emit('closeModal')
    canSubmit.value = true;
  }
}


const showError = ref(false);

const submitToApi = () => {

  if(!canSubmit.value && !refRecord.value.name) {
    return;
  }
  if (refRecord.value.name !== invariantName.value) {
    loading.value = true;
    let dataPatch = {id: props.record.id, name: refRecord.value.name}
    canSubmit.value = false;
    updateOrganization(dataPatch).then(
      result => {
        afterSave(result)
      });
  } else {
    emit('closeModal')
  }
}

// const { jobRoles } = useJobRoles()
const cancel = () => {
  emit('closeModal')
}

</script>
