<template>
  <div class="w-full flex-col justify-start items-start inline-flex m-2">
    <div
      class="w-full flex-col justify-start items-start inline-flex shadow rounded-xl cursor-pointer"
      @click="$emit('open-details')"
    >
      <div class="h-44 w-full flex-col justify-start items-start flex rounded-t-xl">
        <SmartImageBackground
          class="self-stretch grow shrink basis-0 p-2 rounded-t-xl bg-cover bg-center bg-no-repeat"
          :src="course.image?.src"
          :placeholder="course.placeholder?.src"
        >
          <div class="flex justify-between">
            <ProviderLogo :provider="course?.provider" />

            <div class="flex gap-2 flex-col items-end">
              <Pill
                type="info"
                :text="$t(`course_results.type_values.${course.resource_type.toLowerCase().replace(' ', '_')}`)"
                :icon="resourceIconMap[course.resource_type || 'DEFAULT']"
              />
              <Pill
                v-if="currentUser?.addon_catalogs.indexOf(course.catalog) > -1"
                type="info-dark"
                :text="$t('card.included')"
              />
              <Pill
                v-else-if="course.price === 0"
                type="success"
                :text="$t('card.free')"
              />
              <Pill
                v-else
                type="warn"
                :text="$t('card.paid')"
              />
            </div>
          </div>
        </SmartImageBackground>
      </div>
      <div class="p-4 h-[12rem] w-full bg-white rounded-b-xl flex flex-col">
        <div class="flex-col w-full gap-1 flex">
          <div class="justify-start items-start gap-1 inline-flex">
            <div
              v-if="course.completed"
              class="text-lg uil uil-check-circle text-green-600"
            />
            <div class="grow shrink basis-0 text-gray-900 text-lg font-bold leading-normal line-clamp-1">
              {{ course.title }}
            </div>
          </div>
          <div
            class="text-gray-700 text-sm font-normal leading-tight line-clamp-2"
            v-html="course.description"
          />
        </div>
        <div class="flex flex-col gap-1 pt-1 pb-4">
          <SkillLevelIndicator :level="course.level" />
          <div
            v-if="collections_count > 0"
            class="flex gap-1 text-sm font-bold text-blue-700"
          >
            <i class="icon-folder-heart-filled" />
            {{ $t('collections.in_collection') }}
          </div>
        </div>
        <div class="flex gap-4 mt-auto">
          <LikeUnlike
            :resource="course"
            :status="course.user_resource_status"
          />
          <Button
            class="flex-shrink-0 z-0"
            icon="uil uil-folder-heart"
            size="normal"
            severity="secondary"
            @click="showAddCourseToCollectionModal"
          />
          <Button
            class="w-full"
            :label="$t('card.more_details')"
            @click="$emit('open-details')"
          />
        </div>
      </div>
    </div>
    <CollectionManager
      v-if="isAuthenticated && course"
      v-model="showDialog"
      :fit="true"
      :course="course"
      :only-create-mode="onlyCreateMode"
      class="w-[600px]"
      data-test="add-to-collection"
      @refreshDetail="refreshDetail"
    />
  </div>
</template>

<script setup>
import {defineProps, ref} from 'vue'
import Button from 'primevue/button'
import SkillLevelIndicator from './SkillLevelIndicator.vue';
import LikeUnlike from './common/LikeUnlike.vue';
import SmartImageBackground from './common/SmartImageBackground.vue';
import Pill from './common/Pill.vue';
import Resource from "@/resources/Resource";

import { useUserData } from "@/composables/userData";
import CollectionManager from "@/components/collections/CollectionManager.vue";
import ProviderLogo from "@/components/common/ProviderLogo.vue";

const { getUser, isAuthenticated } = useUserData();
const currentUser = getUser()

const resourceIconMap = {
  AUDIO: 'uil uil-headphones',
  VIDEO: 'uil uil-video',
  PODCAST: 'uil uil-microphone',
  DOCUMENT: 'uil uil-file',
  COURSE: 'uil uil-tv-retro',
  WEBINAR: 'uil uil-webcam',
  YOUTUBE_PLAYLIST: 'uil uil-layer-group',
  CODECADEMY_PLAYLIST: 'uil uil-layer-group',
  DEFAULT: 'uil uil-list-ul',
}

const props = defineProps({
  course: { type: Resource, required: true},
  onlyCreateMode: { type: Boolean, default: false}
})

const collections_count = ref(0)
const showDialog = ref(false);
collections_count.value = props.course.collections

const showAddCourseToCollectionModal = (event) => {
  event.stopPropagation();
  showDialog.value = true;
}
const refreshDetail = async (id) => {
  console.log('TODO: refreshDetail',id)
  collections_count.value = collections_count.value + 1
}

</script>
