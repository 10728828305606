<template>
  <div class="group flex z-30">
    <Button
      class="flex-shrink-0"
      :icon="mainButtonIcon"
      severity="secondary"
      :size="size"
      @click.stop=""
    />
    <div
      class="button-wrapper"
    >
      <Button
        class="like-button"
        :icon="thumbsUpIcon"
        severity="secondary"
        :size="size"
        @click.stop="onThumbsUpClick"
      />
      <Button
        class="like-button"
        :icon="thumbsDownIcon"
        severity="secondary"
        :size="size"
        @click.stop="onThumbsDownClick"
      />
    </div>
  </div>
</template>
<script setup>
import Button from 'primevue/button'
import { ref, defineProps, computed } from 'vue'
import { setResourceStatus } from '../../api/user'
import { pushToDataLayer } from '../../composables/useGoogleTagManager';
import Resource from "@/resources/Resource.js";


const props = defineProps({
  status: { type: String, default: 'NEUTRAL' },
  size: { type: String, default: 'normal' },
  resource: { type: Resource, required: true}
})
const status = ref(props.status)


const mainButtonIcon = computed(() => {
  if (status.value === 'LIKED') {
    return 'icon-thumbs-up-solid'
  } else if (status.value === 'UNLIKED') {
    return 'icon-thumbs-down-solid'
  } else {
    return 'icon-thumbs-up'
  }
})
const thumbsUpIcon = computed(() => {
  if (status.value === 'LIKED') {
    return 'icon-thumbs-up-solid'
  } else {
    return 'icon-thumbs-up'
  }
})
const thumbsDownIcon = computed(() => {
  if (status.value === 'UNLIKED') {
    return 'icon-thumbs-down-solid'
  } else {
    return 'icon-thumbs-down'
  }
})
const onThumbsUpClick = () => {
  if (status.value === 'LIKED') {
    changeStatus('NEUTRAL')
  } else {
    changeStatus('LIKED')
  }
}
const onThumbsDownClick = () => {
  if (status.value === 'UNLIKED') {
    changeStatus('NEUTRAL')
  } else {
    changeStatus('UNLIKED')
  }
}
const changeStatus = async (state) => {
  await setResourceStatus(props.resource.id, props.resource.resource_type, state)
  status.value = state
  const ev = {
      event: (state == 'LIKED')?'add_to_like':'add_to_dislike',
      course:{
      title:props.resource.title,
      level:props.resource.level,
      type:props.resource.resource_type,
      price:props.resource.price,
      provider: props.resource.provider
      }
    }
    if (state == 'LIKED' || state == 'UNLIKED'){
      pushToDataLayer(ev)
    }
}
</script>
<style scoped>
.button-wrapper{
  @apply opacity-0 group-hover:opacity-100 invisible group-hover:visible flex absolute gap-x-4 bg-blue-100 rounded-full p-2 -m-2 transition ease-in-out duration-500
}

.like-button{
  @apply hover:bg-blue-300
}
</style>
