import { ref, reactive } from 'vue';

import {
  fetchUserData,
  patchUser,
  ROLES,
  switchEmailNotification,
  setAgreement
} from '@/api/user';
import { useSessionStorage } from '@vueuse/core';
import {responseApi} from "@/api";
import config from "@/config";
import { ErrorBus, ErrorCommands } from '@/eventBus';
// import { useUserCollections } from '@/composables/useUserCollections';
import { loadLanguagesList } from '@/composables/useElasticCourseResults';
import { onUserLogin } from '@/lib/userReplay';
import Subscription from "@/resources/Subscription";
// import { getKeyCloak } from './useSSO';
import User from "@/resources/User";
import { useAuthStore } from '../stores';


// state
const user = reactive({
  id: '',
  name: '',
  surname: '',
  first_name: '',
  last_name: '',
  email: '',
  active: false,
  is_superuser: false,
  occupation_1: '',
  occupation_2: '',
  skill_names: [],
  occupation_1_name: '',
  occupation_2_name: '',
  date_onboarding: null,
  role: '',
  skills: ref([]),
  organization_id: '',
  organization_info: {},
  report: '',
  itw_status : '',
  profile: '',
  language: null,
  lang: '',
  current_subscription: {},
  addon_catalogs: [],
  groups_assigned_counter: 0,
  is_loaded: false,
  email_notification : true,
  privacy_policy : false,
  made_choice : false
});

const logoutPerformed = useSessionStorage('logout', false);
const isAuthenticated = ref(false);
const userLang = ref(false);
// const token = ref(null);

// function updateToken(newToken){
//   token.value = newToken;
// }

// helpers

// function updateUser(data) {
//   Object.assign(user, data);
// }

function setUser(data) {
  user.id = data.id || 0;
  user.name = data.first_name || '';
  user.surname = data.last_name || '';
  user.first_name = data.first_name || '';
  user.last_name = data.last_name || '';
  user.email = data.email || '';
  user.active = data.is_active || false;
  user.is_superuser = data.is_superuser || false;
  user.occupation_1 = data.occupation_1 || '';
  user.occupation_2 = data.occupation_2 || '';
  user.date_onboarding = data.date_onboarding || null;
  user.skill_names = data.skill_names || [];
  user.occupation_1_name = data.occupation_1_name || "";
  user.occupation_2_name = data.occupation_2_name || "";
  user.role = data.role || '';
  user.skills = data.skills || [];
  user.owner_of = data.owner_of || [];
  user.organization_id = data.organization || null;
  user.organization_info = data.organization_info || {};
  user.report = data.report || '';
  user.itw_status = data.itw_status || '';
  user.profile = data.profile;
  user.language = data.language || null;
  user.lang = data.lang || 'en';
  user.current_subscription = data.current_subscription ? new Subscription(data.current_subscription) : {};
  user.addon_catalogs = data.addon_catalogs || [];
  user.groups_assigned_counter = data.groups_assigned_counter || 0;
  user.email_notification = data.email_notification || false;

  user.privacy_policy = (data.profiled_info || false)? data.profiled_info.privacy_policy: false;
  user.made_choice = (data.profiled_info || false)? data.profiled_info.made_choice: false;
}

const clearUser = () => {
  isAuthenticated.value = false;
  setUser({})
  user.is_loaded = true;
  // persistUserData('')
  // navigator.serviceWorker.controller.postMessage({
  //   type: 'CLEAR_TOKEN'
  // });

  // persistToken("")
  // token.value = null;
}

async function refreshUserData() {
    // console.log('refreshUserData');
    const result = await fetchUserData(user.id);
    // console.log(result)
    if (!result || result.error) {
        if(result) ErrorBus.emit(ErrorCommands.ERROR, { message: result.error });
        clearUser();
    } else {
      isAuthenticated.value = true;
      // persistUserData(result);
      setUser(result);
      user.is_loaded = true;
    }
}

function getCurrentSubscription(){
  if (!user || !user.current_subscription.id || user.current_subscription.id === "NO_ID" ){ // || user.current_subscription.plan_status !== 'active'
    return new Subscription({});
  }
  // TODO check if user.current_subscription is a Subscription instance?
  return user.current_subscription;
}

// async function SubscriptionData(){
//   const result = await Subscription();
//   //console.log(result);
//   return result.row;
// }

// const userAnalytics = computed(() => user.analytics)

// initialize state
// this must be done on module initialization
// because otherwise it happens too late for
// onBeforeRouteUpdate event (at least on Chrome)

function useUserData() {

  const getUser = () => {
    return user instanceof User ? user : new User(user) ;
  }

  const loadedFromKc = () => {
    user.is_loaded = true;
  }

  const loginUser = async () =>{
    // console.log('loginUser')
    isAuthenticated.value = true;
    const authStore = useAuthStore();

    //const KC = await getKeyCloak();

    user.id = authStore.getUserId();

    // console.log("user id from KC", user.id)
    // updateToken(KC.token);
    await refreshUserData();
    onUserLogin(user);
    userLang.value = user.lang;
    // const { getUserCollections } = useUserCollections()
    // getUserCollections(true)
    // getUserAnalytics(true)
    loadLanguagesList()
  }

  const logoutUser = async() => {
      clearUser();
      // const home =  window.location.origin
      //   ? window.location.origin + '/'
      //   : window.location.protocol + '/' + window.location.host + '/';
      //const home = config.BACKEND_URL + `/api/v2/accounts/logout/`
      // just passing '/' did not work
      // const KC = await getKeyCloak();
      // KC.logout({redirectUri: home});
      const authStore = useAuthStore();
      authStore.logout();
      logoutPerformed.value = true;
    }

  const initiateLogin = async() => {
    // const KC = await getKeyCloak();
    // KC.login();
    // window.location.href = config.BACKEND_URL + `/api/v2/accounts/login`
    window.location.href = config.BACKEND_URL + `/api/v2/accounts/openid_connect/login/?process=login`
  }

  const requestPasswordChange = async() => {
    // const KC = await getKeyCloak();
    // KC.login({action: "UPDATE_PASSWORD"});
    const authStore = useAuthStore();
    authStore.changePassword();
  }

  const patchUserData = async (data) => {
    const newUserData = await patchUser(user.id, data)
    if (newUserData) {
      setUser(newUserData)
    }
    return newUserData;
  }
  const callSwitchEmailNotification = async () => {
    const result = await switchEmailNotification(user.id)
    const newUserData =  responseApi(User, result, true, true);
    if (newUserData) {
      setUser(newUserData)
    }
    return newUserData;
  }
  const callSetAgreement = async (user_id, data) => {
    const result = await setAgreement(user_id, data)
    const newUserData =  responseApi(User, result, true, true);
    if (newUserData) {
      setUser(newUserData)
    }
    return newUserData;
  }

  return {
    user,
    getUser,
    initiateLogin,
    isAuthenticated,
    userLang,
    loginUser,
    logoutUser,
    refreshUserData,
    callSwitchEmailNotification,
    callSetAgreement,
    requestPasswordChange,
    // updateToken,
    patchUserData,
    clearUser,
    loadedFromKc,
    // token
  }
}

/**
 * for use in router and components where the other methods are not needed
 */
function useAuthenticated() {
  // isAuthenticated.value will change
  return { isAuthenticated }
}

function useUserRoles(){ // TODO wrap user data in a user resource
  const userIsAnyAdmin  = () => {
    return userIsAdmin() || userIsSales() || userIsOrganizationAdmin()
  }
  const userIsAdmin = () => {
    return user.role === ROLES.ADMIN;
  }
  const userIsSales = () => {
    return user.role === ROLES.SALES;
  }
  const userIsOwnerOfOrg = (orgId) => {
    return (user?.owner_of || []).indexOf(orgId) > -1;
  }
  const userIsOrganizationAdmin = () => {
    return user.role === ROLES.SUPERVISOR;
  }
  const userIsManager = () => {
    return user.role === ROLES.MANAGER;
  }
  const userOrganizationId = () => {
    return user.organization_id;
  }
  const userHasPersonalOrganization = () => {
    return user.organization_info.personal;
  }
  const userId = () => {
    return user.id;
  }

  const userIsMember = () =>{
    return user.role === ROLES.MEMBER;
  }

  const userNeedsToChooseRole = () => {
    if(!isAuthenticated.value) return false;
    return (user.occupation_1 === '' || user.occupation_1 === null);
  }

  return {
    userIsAdmin,
    userIsSales,
    userIsOwnerOfOrg,
    userIsAnyAdmin,
    userIsMember,
    userIsOrganizationAdmin,
    userIsManager,
    userNeedsToChooseRole,
    userId,
    userOrganizationId,
    userHasPersonalOrganization,
  }
}

const BUSINESS_PROFILES = {
  business_user: 2,
  fullon_business_user: 1
}

const LOCALES = [
  {lang: 'it-IT', key: 'it', label: 'Italiano'},
  {lang: 'en-US', key: 'en', label: 'English'},
  //{lang:'fr-FR', key:'fr', label: 'Français'},
]

export {
  BUSINESS_PROFILES,
  clearUser,
  useUserData,
  getCurrentSubscription,
  useAuthenticated,
  useUserRoles,
  LOCALES
}
