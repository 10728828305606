import { ref } from 'vue';

import {getCollections} from "@/api/collections";

export function useCollectionsGlobal () {
  const isGlobalCollectionLoading = ref(true);
  const globalCollections = ref([]);
  const globalCollectionsFilters = [{
    field: 'creator',
    value: 'USER'
  }, {
    field: 'share',
    value: 'SHARE_GLOBAL'
  }];

  const loadGlobalCollection = async () => {
    if (globalCollections.value.length === 0) {
      try {
        const colls = await getCollections(-1, 0, globalCollectionsFilters);
        if (colls && !colls.error) {
          globalCollections.value = colls.results;
        } else {
          globalCollections.value = [];
          console.error('[useGlobalCollection.vue] error: ' + colls.error);
        }
        isGlobalCollectionLoading.value = false;
      } catch (e) {
        console.error('[useGlobalCollection.vue] error: ' + e);
        isGlobalCollectionLoading.value = false;
      }
    }
  };
  loadGlobalCollection();
  const refreshGlobalCollection = async () => {
    globalCollections.value = [];
    loadGlobalCollection();
  };

  return {
    isGlobalCollectionLoading,
    globalCollections,
    refreshGlobalCollection,
  };
}
