import {
  list,
  getById,
  create,
  patch,
  deleteById,
  createUser,
  updateUser,
  // switchProfileUser,
  convertToPayInApp,
} from "@/api/organization.js";
import {
  list as userList
} from '@/api/user.js';
import {
  createPlan,
  updatePlan,
  deletePlanById,
  createUserSubscription,
  renewalPlan,
  setPayedPlanById,
  listPlans,
  listAddons,
  createAddon,
  updateAddon,
  renewalAddon,
  deleteAddonById,
  listAddonsCatalogs,
  updateUsersToAddon,
  removeUsersToAddon,
} from "@/api/plans.js";
import {
  getGroup,
  createGroup,
  updateGroup,
  deleteGroupById,
  updateUsersToOrganizationGroup,
  removeUsersToOrganizationGroup,
  getGroups,
} from "@/api/groups.js";

import { ErrorBus, ErrorCommands } from "@/eventBus";
import Plan from "@/resources/Plan";
import Addon from "@/resources/Addon";
import Group from "@/resources/Group";
import Organization from "@/resources/Organization";
import User from "@/resources/User";
import {useToasts} from "@/composables/useToasts";
// import Collection from "@/resources/Collection.js";
import { responseApi } from "@/api";

function useOrganizations() {
  //const { token } = useUserData();

  const { toastError } = useToasts();

  const getOrganizationById = async id => {
    const results = await getById(id);
    return responseApi(Organization, results, true, true);
  };

  const getOrganizations = async (limit, offset, params) => {
    const results = await list(limit, offset, params);
    return responseApi(Organization, results, false, true);
  };
  const getUsersList = async (limit, offset, params) => {
    const results = await userList(limit, offset, params);
    return responseApi(User, results, false, true);
  };

  const createOrganization = async data => {
    const result = await create(data);
    return responseApi(Organization, result, true, true);
  };

  const updateOrganization = async data => {
    const result = await patch(data);
    return responseApi(Organization, result, true, true);
  };

  const deleteOrganization = async id => {
    const results = await deleteById(id);
    if (results && !results.error) {
      return results;
    }
    // not found
    ErrorBus.emit(ErrorCommands.ERROR, { message: results?.error || 'errors.general' });
    return {error: results?.error || 'errors.general'}
  };


  const getPlans = async (limit, offset, params) => {
    let results = await listPlans(limit, offset, params);
    return responseApi(Plan, results, false, true);
  };
  const getAddons = async (limit, offset, params) => {
    let results = await listAddons(limit, offset, params);
    return responseApi(Addon, results, false, true);
  };

  const createOrganizationAddon = async (id, data) => {
    const result = await createAddon(id, data);
    if(!result.result) {
      return {error: result.error}
    } else {
      return Addon.fromApiResult(result.result);
    }
  };
  const updateOrganizationAddon = async (id, data) => {
    const result = await updateAddon(id, data);
    if(!result.result) {
      return {error: result.error}
    } else {
      return Addon.fromApiResult(result.result);
    }
  };
  const renewalOrganizationAddon = async (id, data) => {
    const result = await renewalAddon(id, data);
    if(!result.result) {
      return {error: result.error}
    } else {
      return Addon.fromApiResult(result.result);
    }
  };
  const updateUsersOfAddon = async (addonId, usersList, remove) => {
    let result = null;
    if (remove) {
      result = await removeUsersToAddon(addonId, usersList);
    } else {
      result = await updateUsersToAddon(addonId, usersList);
    }

    if(!result.result) {
      if (result.error) {
        ErrorBus.emit(ErrorCommands.ERROR, {message: result.error.toString()});
        return {error: result.error};
      } else {
        ErrorBus.emit(ErrorCommands.ERROR, {message: 'errors.general'});
        return {error: 'errors.general'};
      }
    }
    return Addon.fromApiResult(result.result);
  };
  const getAddonsCatalogs = async () => {
    let results = await listAddonsCatalogs();
    return {error: results.error, results: results.result};
  };
  const getOrganizationPlanSubscriptions= async id => {
     let args = {
        'filters':  {
          'for_with_user': {'field': 'for_with_user', 'value': true},
          'organization': {'field': 'organization', 'value': id}
         },
       'sortField': '-start_date'
      };
    let results = await listPlans(args);
    return responseApi(Plan, results, false, true);
  };

  const createOrganizationUser = async (id, data) => {
    const result = await createUser(id, data);
    return responseApi(User, result, true, true);
  };

  const updateOrganizationUser = async (id, data) => {
    const result = await updateUser(id, data);
    return responseApi(User, result, true, true);
  };

  const convertOrganizationToPayInApp = async (id, data) => {
    const result = await convertToPayInApp(id, data);
    return responseApi(Organization, result, true, false);
  };
  // const switchProfileOrganizationUser = async (id, data) => {
  //   const result = await switchProfileUser(id, data);
  //   return responseApi(User, result, true, true);
  // };

  const createOrganizationPlan = async (id, data) => {
    const result = await createPlan(id, data);
    if(!result.result) {
      return {error: result.error}
    } else {
      return Plan.fromApiResult(result.result);
    }
  };
  const updateOrganizationPlan = async (id, data) => {
    const result = await updatePlan(id, data);
    if(!result.result) {
      return {error: result.error}
    } else {
      return Plan.fromApiResult(result.result);
    }
  };
  const renewalOrganizationPlan = async (id, data) => {
    const result = await renewalPlan(id, data);
    if(!result.result) {
      return {error: result?.error}
    } else {
      return Plan.fromApiResult(result.result);
    }
  };

  const getOrganizationGroup = async groupId => {
    let result = await getGroup(groupId);
    return responseApi(Group, result, true, true);
  };
  const createOrganizationGroup = async (id, data) => {
    const result = await createGroup(id, data);
    return responseApi(Group, result, true, true);
  };

  const updateOrganizationGroup = async (id, data) => {
    const result = await updateGroup(id, data);
    return responseApi(Group, result, true, true);
  };

  const deleteOrganizationGroup = async id => {
    const results = await deleteGroupById(id);
    if (results && !results.error) {
      return results;
    }
    ErrorBus.emit(ErrorCommands.ERROR, { message: results?.error || 'errors.general' });
    return {error: results?.error || 'errors.general'}
  };

  const getOrganizationAllGroups = async (limit, offset, params) => {
    let results= await getGroups(limit, offset, params);
    return responseApi(Group, results, false, true);
  };
  const deleteOrganizationPlan = async id => {
    const results = await deletePlanById(id);
    if (results && !results.error) {
      return results;
    }
    toastError('Error', "general.msg_error_saving", { translation: { errormsg: results?.error } })
    return {error: results?.error || 'errors.general'}
  };
  const deleteOrganizationAddon = async id => {
    const results = await deleteAddonById(id);
    if (results && !results.error) {
      return results;
    }
    let formattedErrors =  Plan.parseError(results.error.response?.data)
    toastError('Error',"general.msg_error_saving", { translation: {errormsg: formattedErrors}})
    // ErrorBus.emit(ErrorCommands.ERROR, {message: formattedErrors });
    return {error: results?.error || 'errors.general'}
  };

  const setPayedPlan = async id => {
    const results = await setPayedPlanById(id);
    if (results && !results.error) {
      return results;
    }
    let formattedErrors =  Plan.parseError(results.error.response.data)
    toastError('Error',"plans.msg_payed_failed", { translation: {errormsg: formattedErrors}})
    // ErrorBus.emit(ErrorCommands.ERROR, {message: formattedErrors });
    return {error: results?.error || 'errors.general'}
  };



  const createSubscriptionsForPlan = async (planId, usersList) => {
    let result = await createUserSubscription(planId, usersList);
    if(!result.result) {
      if (result.error) {
        ErrorBus.emit(ErrorCommands.ERROR, {message: result.error.toString()});
        return {error: result.error};
      } else {
        ErrorBus.emit(ErrorCommands.ERROR, {message: 'errors.general'});
        return {error: 'errors.general'};
      }
    }
    return Plan.fromApiResult(result.result);
  };

  const updateUsersToGroup = async (groupId, usersList, remove) => {
    let result = null;
    if (remove) {
      result = await removeUsersToOrganizationGroup(groupId, usersList);
    } else {
      result = await updateUsersToOrganizationGroup(groupId, usersList);
    }

    if(!result.result) {
      if (result.error) {
        ErrorBus.emit(ErrorCommands.ERROR, {message: result.error.toString()});
        return {error: result.error};
      } else {
        ErrorBus.emit(ErrorCommands.ERROR, {message: 'errors.general'});
        return {error: 'errors.general'};
      }
    }
    return Group.fromApiResult(result.result);
  };

  // const refreshOrganizationUserCollections = async (id) => {
  //   const result = await refreshUserCollections(id);
  //   if(!result){
  //     ErrorBus.emit(ErrorCommands.ERROR, { message: 'errors.general' });
  //     return {error: 'errors.general'}
  //   }
  //   if(result.error){
  //     ErrorBus.emit(ErrorCommands.ERROR, { message: result.error });
  //     return {error: result.error}
  //   }
  //   return result;
  // };



  return {
    getUsersList,
    createOrganization,
    createOrganizationUser,
    deleteOrganization,
    getOrganizations,
    getOrganizationById,
    getPlans,
    getAddons,
    getAddonsCatalogs,
    deleteOrganizationAddon,
    createOrganizationAddon,
    updateOrganizationAddon,
    renewalOrganizationAddon,
    updateUsersOfAddon,
    updateOrganization,
    updateOrganizationUser,
    // switchProfileOrganizationUser,
    createOrganizationPlan,
    updateOrganizationPlan,
    getOrganizationAllGroups,
    getOrganizationGroup,
    createOrganizationGroup,
    updateOrganizationGroup,
    deleteOrganizationGroup,
    renewalOrganizationPlan,
    deleteOrganizationPlan,
    getOrganizationPlanSubscriptions,
    createSubscriptionsForPlan,
    updateUsersToGroup,
    setPayedPlan,
    convertOrganizationToPayInApp,
  };
}

export { useOrganizations };
